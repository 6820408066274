<template>
	<div class="bigBox">
		<h2>审核列表</h2>
		<div class="">
			<div class="iptsBox margin-top20">
				<el-form ref="form" :model="form" label-width="80px">
					<div class="flex-sb">
						<div class="row-me">
							<el-input v-model="form.keyword" prefix-icon="Search" placeholder="公司名称/行业名称"
								class="namePut"></el-input>
							<el-select v-model="form.state" placeholder="审核状态" class="electIpt">
								<el-option label="待审批" value="1"></el-option>
								<el-option label="审批通过" value="2"></el-option>
								<el-option label="审批拒绝" value="3"></el-option>
							</el-select>
							<el-button type="primary" icon="Search" class="searchs" @click="searchs">
								搜索</el-button>
							<el-button class="agains" @click="resetting">重置
							</el-button>
						</div>
					</div>
				</el-form>
			</div>
			<div class="tabsBox  margin-top20">
				<el-table ref="singleTable" :data="auditListArr" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column type="index" label="序号" width="70" align="center">
					</el-table-column>
					<el-table-column prop="id" label="客户ID" align="center">
					</el-table-column>
					<el-table-column prop="company_name" label="公司名称" align="center">
					</el-table-column>
					<el-table-column prop="trade_name" label="行业名称" align="center">
					</el-table-column>
					<el-table-column prop="contacts" label="联系人" align="center">
					</el-table-column>
					<el-table-column prop="mobile" label="电话" align="center">
					</el-table-column>
					<el-table-column prop="address" label="地址" align="center">
					</el-table-column>
					<el-table-column prop="email" label="邮箱" align="center">
					</el-table-column>
					<el-table-column prop="create_time" label="申请时间" align="center">
					</el-table-column>
					<el-table-column prop="stateText" label="审核状态" align="center">
					</el-table-column>
					<el-table-column label="操作" align="left" width="140">
						<template #default="scope">
							<div v-if="scope.row.state == 1">
								<el-button type="text" size="small" @click="okDialog(scope.row)">通过</el-button>
								<el-button type="text" size="small" @click="reject(scope.row)">驳回</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="row-me row-center flex-end1 margin-top10" v-if="auditListArr.length>0">
					<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
						:current-page.sync="pageNo" :total="total">
					</el-pagination>
				</div>
			</div>

		</div>

		<!-- 通过弹窗 -->
		<el-dialog v-model="dialogRis" title="审核通过" width="30%">
			<div class="row-me margin-left15"> <span style="margin-top: 8px;">可添加用户人数</span>
				<el-input v-model="user_number" placeholder="请输入" style="width: 346px;margin-left: 20px;" />
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogRis = false" style="width: 100px;height: 40px;">取消</el-button>
					<el-button type="primary" @click="auditOk">
						确定
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		auditListInfo,
		auditInfoExamine
	} from "@/api/customerList/customerList.js"
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	export default {
		data() {
			return {
				dialogRis: false,
				form: {
					keyword: "",
					state: "",

				},
				user_number: "",
				auditListArr: [],
				ids: "",
				pageNo: 1,
				total: 0,
				stateIndex: 2, //2：审批通过，3：审批拒绝
			}
		},
		mounted() {
			this.getAuditList()
		},
		methods: {
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getAuditList()
			},
			// 审核列表表格
			async getAuditList() {
				const res = await auditListInfo({
					state: this.form.state,
					keyword: this.form.keyword,
					page: this.pageNo,
				})
				this.auditListArr = res.data.listInfo
				this.total = res.data.allListNumber
			},
			// 搜索
			searchs() {
				this.getAuditList()
			},
			// 重置
			resetting() {
				this.pageNo = 1
				this.form.keyword = ''
				this.form.state = ''
				this.getAuditList()
			},
			// 点击通过
			okDialog(row) {
				this.ids = row.id;
				this.dialogRis = true;
			},
			// 添加人数确定
			async auditOk() {
				const res = await auditInfoExamine({
					id: this.ids,
					user_number: this.user_number,
					state: 2, //2：审批通过，3：审批拒绝
				})
				ElMessage({
					type: 'success',
					message: '已通过',
				})
				this.getAuditList()
				console.log(this.ids, "pp")
				this.dialogRis = false
			},
			reject(row) {
				ElMessageBox.confirm(
						'确定驳回申请吗？',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(async () => {
						const res = await auditInfoExamine({
							id: row.id,
							state: 3, //2：审批通过，3：审批拒绝
						})
						ElMessage({
							type: 'success',
							message: '驳回成功',
						})
						this.getAuditList()
					})
					.catch(() => {
						ElMessage({
							type: 'info',
							message: '驳回失败',
						})
					})
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	.namePut {
		width: 360px;
		height: 42px;
		margin-right: 10px;
	}

	.agains {
		margin-left: 10px !important;
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	.searchs {
		margin-left: 10px;
		font-size: 16px;
	}

	.electIpt {
		width: 200px;
		height: 40px;
		margin-left: 10px;
	}

	.fenye {
		float: right;
		margin-top: 40px;
	}

	::v-deep .el-button--primary {
		width: 100px;
		height: 40px;
	}

	/deep/.el-button+.el-button {
		margin-left: 0px;
	}
</style>